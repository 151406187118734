import { createRenderer } from 'fela'
import webPreset from 'fela-preset-web'
import basicStyles from './basic-styles'

export function styleRenderer(rest = {}, customBasicStyles) {
  const options = rest || {}

  const renderer = createRenderer({
    ...options,
    plugins: [...webPreset, ...(options.plugins || [])].filter(Boolean)
  })

  Object.keys(basicStyles).forEach(selector =>
    renderer.renderStatic(basicStyles[selector], selector)
  )

  if (customBasicStyles) {
    Object.keys(customBasicStyles).forEach(selector =>
      renderer.renderStatic(customBasicStyles[selector], selector)
    )
  }

  return renderer
}
