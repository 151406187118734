export const colors = {
  contentBg: "#f5f5f5",
  bg: "#fff",
  border: "#eee",
  navigationBg: "#fff",
  primary: "#7cccc2",
  secondary: "#494f61",
  primaryDark: "#544399",
  primaryLight: "#a3d3cd",
  disabled: "#ddd",

  gray: "#656565",
  lightGreen: "#8BC9C3",
  darkGreen: "#71A39E",
  lightGray: "#D1CFD0",
  lightBlue: "#828DAD",
  blue: "#494F61",
  darkBlue: "#454b61",
  body: "#363A47",

  diffuse: "#888888",
  // bg: "#f5f5f5",
  secondaryBg: "#ffffff",
  // border: "#eeeeee",
  // primary: "#85D986",
  primaryContrast: "#ffffff",
  // secondary: "#EC8B46",
  secondaryContrast: "#ffffff",

  black: "#000000",
  grey1: "#161618",
  grey2: "#3D3D43",
  grey3: "#57575C",
  grey4: "#75757A",
  grey5: "#C0C0C6",
  grey6: "#E8E8EE",
  grey7: "#F4F4FA",
  grey8: "#F0F0F0",
  white: "#FFFFFF",

  destructive: "#C60C46",
  destructiveLight: "#db0f4f",
};

export const shadows = {
  small: "0px 1px 3px 0px rgb(0, 0, 0, 0.1)",
  big: "0 5px 10px rgb(0, 0, 0, 0.1)",
};

export const layout = {
  grid: 8,
  radius: 2,
//  maxContentWidth: 1200,
  maxModalWidth: 600,
};

export const card = {
  bg: colors.secondaryBg,
};

export const button = {
  bg: colors.secondaryBg,
};

// export const text = {
//   button: {
//     size: 14,
//     spacing: 2,
//   },
//   h1: {
//     fontSize: 24,
//     fontWeight: "normal",
//     letterSpacing: 2,
//   },
//   h2: {
//     fontSize: 18,
//     fontWeight: "normal",
//     letterSpacing: 1.6,
//   },
//   h3: {
//     fontSize: 14,
//     fontWeight: "normal",
//     letterSpacing: 1.2,
//   },
//   p: {
//     fontSize: 12,
//     fontWeight: "normal",
//     letterSpacing: 1,
//   },
//   small: {
//     fontSize: 10,
//     fontWeight: "normal",
//     letterSpacing: 1,
//   },
// };

// no s in here as thats the default at 0-752
export const breakpointSizes = {
  m: 752,
  l: 1025,
  maxContentWidth: 1280,
  xl: 1600,
};

// there is no fromS as that'd be the default styles and we do not want
// those to be part of a min-width: 0px media queries due to CSS performance
const breakpoints = {
  // duplicate of untilM
  // > 0 && < 752
  get onlyS() {
    return this.untilM;
  },

  // < 752
  untilM: "@media (max-width: " + (breakpointSizes.m - 1) + "px)",
  // >= 752
  fromM: "@media (min-width: " + breakpointSizes.m + "px)",
  // >= 752 && < 1050
  get onlyM() {
    return this.fromM + " and " + this.untilL.substring(7);
  },

  // < 1050
  untilL: "@media (max-width: " + (breakpointSizes.l - 1) + "px)",
  // >= 1050
  fromL: "@media (min-width: " + breakpointSizes.l + "px)",
  // >= 1050 && < 1600
  get onlyL() {
    return this.fromL + " and " + this.untilXL.substring(7);
  },
  // < 1600
  untilXL: "@media (max-width: " + (breakpointSizes.xl - 1) + "px)",
  // >= 1600
  fromXL: "@media (min-width: " + breakpointSizes.xl + "px)",
  fromMaxContentWidth:
    "@media (min-width: " + breakpointSizes.maxContentWidth + "px)",
  // duplicate of fromXL
  // >= 1600
  get onlyXL() {
    return this.fromXL;
  },

  portrait: "@media (orientation: portrait)",

  get isPortrait() {
    return this.portrait;
  },

  landscape: "@media (orientation: landscape)",

  get isLandscape() {
    return this.landscape;
  }
};

const fonts = {};

const defaultTheme = {
  breakpoints,
  button,
  card,
  colors,
  fonts,
  grid: 8,
  layout,
  radius: 2,
  shadows,
  styles: {},
};

const defaulTextVariant = {
  // fontWeight: "normal",
  fontFamily: "Roboto Condensed, source sans pro, sans-serif",
  fontWeight: 400,
  letterSpacing: 1,
};

export default {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors
  },
  font: "Roboto Condensed, source sans pro, sans-serif",
  layout: {
    ...defaultTheme.layout,
    radius: 6,
    // radius: 15,
    //   border: `#222`
  },
  grid: 8,
  textVariants: {
    h0: {
      fontSize: 140,
      ...defaulTextVariant,
      fontWeight: 400,
    },
    h1: {
      fontSize: 100,
      ...defaulTextVariant,
      fontWeight: 400,
    },
    h2: {
      fontSize: 80,
      ...defaulTextVariant,
      fontWeight: 400,
    },
    h3: {
      fontSize: 50,
      ...defaulTextVariant,
      fontWeight: 400,
    },
    h4: {
      fontSize: 40,
      ...defaulTextVariant,
      fontWeight: 400,
    },
    h5: {
      fontSize: 30,
      ...defaulTextVariant,
    },
    h6: {
      fontSize: 18,
      ...defaulTextVariant,
    },
    largeBody: {
      fontSize: 30,
      ...defaulTextVariant,
      fontWeight: "light",
    },
    body: {
      fontSize: 22,
      ...defaulTextVariant,
      letterSpacing: 0,
      lineHeight: "32px"
    },
    bodyS: {
      fontSize: 16,
      ...defaulTextVariant,
      letterSpacing: 0,
      fontWeight: "light",
    },
    small: {
      fontSize: 14,
      ...defaulTextVariant,
      letterSpacing: 0,
    },
    mini: {
      fontSize: 11,
      ...defaulTextVariant,
      letterSpacing: 0,
    },
    button: {
      fontSize: 16,
      ...defaulTextVariant,
      fontWeight: "bold",
    },
  },
};
