import React from "react";
import { rehydrate } from "fela-dom";
import { ThemeProvider, StyleProvider, styleRenderer } from "./src/components/fela";
import theme from "./src/theme";
import "./global.css";

// import { createRenderer } from 'fela'
// import { RendererProvider } from 'react-fela'



export const wrapRootElement = ({ element }) => {
  const renderer = styleRenderer();
  rehydrate(renderer);

  // const renderer = createRenderer()

  return (
    <StyleProvider renderer={renderer}>
        {/* <RendererProvider renderer={renderer}> */}
          <ThemeProvider theme={theme}>{element}</ThemeProvider>
        {/* </RendererProvider> */}
        </StyleProvider>
  );
};