export default {
  html: {
    boxSizing: 'border-box'
  },

  '*, *:before, *:after': {
    boxSizing: 'border-box'
  },

  body: {
    WebkitTextSizeAdjust: '100%'
  },

  'h1, h2, h3, h4, h5, h6, p, ol, ul': {
    margin: 0,
    padding: 0,
    fontWeight: 'normal'
  },

  'em, i, b': {
    fontStyle: 'normal',
    fontWeight: 'normal'
  },

  'ol, ul': {
    listStyle: 'none'
  }
}
