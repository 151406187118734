import React from "react";
import { ThemeProvider as FelaThemeProvider } from "react-fela";

export const colors = {
  body: "#222222",
  diffuse: "#888888",
  bg: "#f5f5f5",
  secondaryBg: "#ffffff",
  border: "#eeeeee",
  primary: "#85D986",
  primaryContrast: "#ffffff",
  secondary: "#EC8B46",
  secondaryContrast: "#ffffff",

  black: "#000000",
  grey1: "#161618",
  grey2: "#3D3D43",
  grey3: "#57575C",
  grey4: "#75757A",
  grey5: "#C0C0C6",
  grey6: "#E8E8EE",
  grey7: "#F4F4FA",
  grey8: "#F0F0F0",
  white: "#FFFFFF",

  destructive: "#C60C46",
  destructiveLight: "#db0f4f",
};

export const shadows = {
  small: "0px 1px 3px 0px rgb(0, 0, 0, 0.1)",
  big: "0 5px 10px rgb(0, 0, 0, 0.1)",
};

export const layout = {
  grid: 8,
  radius: 2,
  maxContentWidth: 1200,
  maxModalWidth: 600,
};

export const card = {
  bg: colors.secondaryBg,
};

export const button = {
  bg: colors.secondaryBg,
};

export const text = {
  button: {
    size: 14,
    spacing: 2,
  },
  h1: {
    fontSize: 24,
    fontWeight: "normal",
    letterSpacing: 2,
  },
  h2: {
    fontSize: 18,
    fontWeight: "normal",
    letterSpacing: 1.6,
  },
  h3: {
    fontSize: 14,
    fontWeight: "normal",
    letterSpacing: 1.2,
  },
  p: {
    fontSize: 12,
    fontWeight: "normal",
    letterSpacing: 1,
  },
  small: {
    fontSize: 10,
    fontWeight: "normal",
    letterSpacing: 1,
  },
};

// no s in here as thats the default at 0-752
export const breakpointSizes = {
  m: 752,
  l: 1050,
  xl: 1600,
};

// there is no fromS as that'd be the default styles and we do not want
// those to be part of a min-width: 0px media queries due to CSS performance
const breakpoints = {
  // duplicate of untilM
  // > 0 && < 752
  get onlyS() {
    return this.untilM;
  },

  // < 752
  untilM: "@media (max-width: " + (breakpointSizes.m - 1) + "px)",
  // >= 752
  fromM: "@media (min-width: " + breakpointSizes.m + "px)",
  // >= 752 && < 1050
  get onlyM() {
    return this.fromM + " and " + this.untilL.substring(7);
  },

  // < 1050
  untilL: "@media (max-width: " + (breakpointSizes.l - 1) + "px)",
  // >= 1050
  fromL: "@media (min-width: " + breakpointSizes.l + "px)",
  // >= 1050 && < 1600
  get onlyL() {
    return this.fromL + " and " + this.untilXL.substring(7);
  },
  // < 1600
  untilXL: "@media (max-width: " + (breakpointSizes.xl - 1) + "px)",
  // >= 1600
  fromXL: "@media (min-width: " + breakpointSizes.xl + "px)",
  // duplicate of fromXL
  // >= 1600
  get onlyXL() {
    return this.fromXL;
  },
  portrait: "@media (orientation: portrait)",

  get isPortrait() {
    return this.portrait;
  },

  landscape: "@media (orientation: landscape)",

  get isLandscape() {
    return this.landscape;
  }
};

const fonts = {};

const defaultTheme = {
  breakpoints,
  button,
  card,
  colors,
  fonts,
  grid: 8,
  layout,
  radius: 2,
  shadows,
  styles: {},
  text,
};

export const ThemeProvider = ({ children, theme }) => (
  <FelaThemeProvider theme={{ ...defaultTheme, ...theme }} overwrite={false}>
    {children}
  </FelaThemeProvider>
);
